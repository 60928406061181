import background from '../assets/images/covers/turkey-cover.svg'

export const turkeyContent = (t) => {
  return {
    columnsCover: {
      cover: true,
      illustration: {
        src: background,
        alt: t('TURKEY_SEARCH_TITLE'),
      },
      text: {
        title: t('TURKEY_SEARCH_TITLE'),
        description: t('TURKEY_SEARCH_SUBTITLE'),
      },
    },
  }
}
